/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';
//import withWrapper from '../components/wrapper.js';
//import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { Link, graphql, StaticQuery } from "gatsby";



export default function speedtest (props) {
  return (
          <div style={{minHeight: '100vh', position: 'relative', paddingBottom:'58px', width:'100%',overflow:'hidden'}}>
            BOB!!!!
            <div style={{width:'100px'}}>
              <Img fixed={props.data.logoEl.childImageSharp.fixed} />
            </div>
          </div>
  );
}

export const query = graphql`
  query {
    logoEl: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`

